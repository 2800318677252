<template>
  <v-container >
    <v-card height="auto" width="100%" class="justify-center" center outlined mx-auto>
      <v-layout class="flex_mobile justify-center" >  
        <v-form style="width: 400px; ">
          <v-card max-width="500" class="mx-auto pa-1 mr-3" color="transparent" outlined >
            <div>사용자 정보 {{preUser_ID}}</div>

            <v-text-field dense outlined style="height: 45px;"
              id="user_ID" 
              ref="user_ID" 
              label="등록 ID✽" 
              v-model="user_ID"
              :disabled="user_ID_disabled" 
            />

            <v-select dense outlined style="height: 45px;"
              id="company" 
              ref="company" 
              label="소속경영체✽" 
              v-model="company"
              :items="companyItems"
              :menu-props="{ top: false, offsetY: true }"
              item-text="name"
              return-object
              @change="changeCompany()"
              @focus="comboCompanies()"
            />

            <v-layout wrap>
              <v-flex xs12 lg6>
                <v-text-field dense outlined style="height: 45px;"
                  id="user_name" 
                  ref="user_name" 
                  label="성명✽" 
                  v-model="user_name" 
                />
              </v-flex>

              <v-flex xs12 lg6>
                <v-text-field dense outlined class="ml-1" style="height: 50px;"
                  id="phone" 
                  ref="phone" 
                  label="연락처✽" 
                  placeholder="숫자만 입력"
                  v-model="phone"
                  @keyup="getPhoneMask(phone)"
                />
              </v-flex>
            </v-layout>
          
            <div style="height: 0px;">알림수신 ✽</div>
            
            <v-radio-group dense outlined row 
              id="call_YN" 
              v-model="call_YN"
            >
                <v-radio ref="call_y" label="동의" value="Y" color="#3a4f3f" ></v-radio>
                <v-radio label="거부" value="N" color="#88191a"></v-radio>
            </v-radio-group>

            <div style="height: 0px; margin-top: -5px">사용자 유형(권한) ✽ </div>
              <v-radio-group dense outlined row 
                id="user_type" 
                v-model="user_type"
              >
            <v-layout wrap>
                <v-flex xs12 lg6>
                  <v-radio label="시스템 관리자" value="UT03" color="#88191a" ></v-radio>
                </v-flex>

                <v-flex xs12 lg6>
                  <v-radio label="현장 관리자" value="UT05" color="#38487B"></v-radio>
              </v-flex>
            </v-layout>

            <v-layout wrap>
                <v-flex xs12 lg6>
                  <v-radio ref="user_type" label="조회자" value="UT08" color="#9EB0A2"></v-radio>
                </v-flex>

                <v-flex xs12 lg6>
                  <v-radio label="슈퍼관리자" value="UT01" color="#FFE081" v-show="this.$getters.userInfo.grade == 'UT01'"></v-radio>
              </v-flex>
            </v-layout>
          </v-radio-group>

          <v-data-table dense outlined class="pa-1 ml-1 mr-1 mb-4 elevation-2 v-data-table1" style="margin-top: -10px"
              v-if="user_type > 'UT01'"
              id="controlItems" 
              ref="controlItems"
              :headers="controlHeaders"
              :items="controlItems"
              :items-per-page= "100"
              no-data-text="관할 농장/사업장이 업습니다."
              fixed-header
              hide-default-header
              hide-default-footer
              v-show= this.preUser_ID 
            >
            <!-- 관힐농장/사업장 편집 시작-->
              <template v-slot:top>
                  <v-dialog
                    v-model="dialogNewControl"
                    max-width="500px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-layout class="layout-class rounded pr-2 pl-2 pt-1">
                        <div>관할농장/사업장</div>
                        <v-spacer></v-spacer>
                        <v-icon
                          color=white 
                          v-bind="attrs"
                          v-on="on"
                        >
                        mdi-table-plus
                        </v-icon>
                      </v-layout>
                    </template>
                    <v-card height="auto">
                      <v-card-title>
                        <span class="text-h5">관할농장/사업장 추가</span>
                      </v-card-title>
          
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col>
                              <v-select dense outlined style="height: 45px; width: 200px; margin-top: 5px"
                                id="controlCompany" 
                                ref="controlCompany"
                                v-model="controlCompany"
                                label="경영체"
                                :items="controlCompanyItems"
                                item-text="name"
                                return-object
                                clearable
                                @change="changeControlCompany()"
                                @focus="comboControlCompanies()"
                              ></v-select>
                            </v-col>
                            <v-col>
                              <v-select dense outlined style="height: 45px; width: 200px; margin-top: 5px"
                                id="controlFarm" 
                                ref="controlFarm"
                                v-model="controlFarm"
                                label="농장/사업장"
                                :items="controlFarmItems"
                                item-text="name"
                                return-object
                                @change="changeControlFarm()"
                                @focus="comboControlFarms()"
                              ></v-select>
                            </v-col>
                          </v-row>
                          <v-textarea outlined dense rows="2" style="height: auto; "
                            label="비고"
                            id="controlHistory" 
                            ref="controlHistory" 
                            v-model="controlHistory"
                            value=""
                          ></v-textarea>
                        </v-container>
                      </v-card-text>
          
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          class="new-btn"
                          text
                          @click="closeNewControl"
                        >
                          닫기
                        </v-btn>
                        <v-btn
                          class="normal-btn"
                          text
                          @click="saveControl"
                        >
                          저장
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card style="border-radius:15px; padding:10px;">
                      <v-card-title>
                        <div>
                          <div class="text-h7"> {{deleteControlID}} </div>
                          <div style="">{{ deleteFarmName }}</div>
                          <div class="text-h8">해당농장/사업장을 관할에서 삭제 하시겠습니까?</div>
                        </div>
                      </v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="new-btn" text @click="closeDelete()">취소</v-btn>
                        <v-btn class="normal-btn" text @click="deleteItemConfirm">확인</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
            <!-- 관힐농장/사업장 편집 끝-->
            </v-data-table>
            <v-combobox dense outlined style="height: 45px;" 
              id="user_duty" 
              ref="user_duty"
              label="직책" 
              placeholder="항목이 없으면 직접 입력하세요"
              :items="['대표','이사','본부장','농장/사업장장','부장','과장','대리','사원','기타']"
              :menu-props="{ top: false, offsetY: true }"
              v-model="user_duty"
              clearable 
            />
            <v-layout wrap>
              <v-flex xs6 lg6>
                <v-text-field dense outlined style="height: 50px;"
                id="email_id" 
                ref="email_id" 
                label="이메일" 
                v-model="email_id" 
                />
              </v-flex>

              <v-flex xs1 lg1>
                <div style="line-height: 35px; text-align: center;">
                  @
                </div>
              </v-flex>

              <v-flex xs5 lg5>
                <v-combobox dense outlined style="height: 50px;"
                id="email_host" 
                ref="email_host" 
                :items="['gmail.com','hotmail.com','naver.com','daum.net',]"
                :menu-props="{ top: false, offsetY: true }"
                placeholder="항목이 없으면 직접 입력하세요"
                v-model="email_host" 
              />
              </v-flex>
            </v-layout>

          <v-layout style="height: 50px;">
            <v-flex xs12 lg6>
              <v-menu
                v-model="menuJoindate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field dense outlined 
                    id="joindate" 
                    ref="joindate" 
                    v-model="joindate"
                    label="등록일✽"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  no-title locale="ko-KR"
                  v-model="joindate"
                  @input="menuJoindate = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 lg6>
              <v-menu
                v-model="menuDeldate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field dense outlined clearable class="ml-1"
                    id="deldate" 
                    ref="deldate" 
                    v-model="deldate"
                    label="말소일"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  no-title locale="ko-KR"
                  v-model="deldate"
                  @input="menuDeldate = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
            
            <v-textarea outlined dense rows="2" name="input-7-4" 
              label="비고"
              id="history" 
              ref="history" 
              v-model="history"
              value=""
              persistent-hint :hint="hintUser"
            ></v-textarea>

            <v-card-actions class="d-block" >
              <v-row justify= "center" >
                <v-spacer></v-spacer>
                <v-btn class="rounded-pill elevation-7 normal-btn" style="height: 30px; width: 100px; margin: 10px;" 
                  :loading="loadingSave"
                  id="save" 
                  ref="save" 
                  type="submit" 
                  @click.prevent="save()"
                >
                  저장
                </v-btn>
                <v-btn class="rounded-pill elevation-7 new-btn" style="height: 30px; width: 100px; margin: 10px;" 
                  id="newRegister" 
                  ref="newRegister" 
                  @click="newRegister()">
                  새로등록
                </v-btn>
                <v-spacer ></v-spacer>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-form>

        <v-card outlined height="auto" width="700" color="transparent" >
         <v-layout ml-2 mt-2>
          <v-radio-group dense outlined row style="height: 30px; margin-top: 2px"
                id="selOption" 
                ref="selOption" 
                v-model="selOption"
              >
                <v-radio
                  label="등록" value="live" color="#3a4f3f" @click="refreshList()"
                ></v-radio>
                <v-radio
                  label="말소" value="del" color="#88191a"  @click="refreshList()"
                ></v-radio>
                <v-radio
                  label="전체" value="all" color="#38487B"  @click="refreshList()"
                ></v-radio>
            </v-radio-group>
          </v-layout>

          <v-text-field
            class="mb-2 ml-1"
            style="max-width:300px;"
            v-model="search"
            append-icon="mdi-magnify"
            label="경영체명이나 사용자명을 입력하세요"
            single-line
            hide-details
            clearable
          ></v-text-field>
          
          <v-data-table dense class="mx-auto pa-2 elevation-3" 
            :loading="loadingUser" 
            id="items" 
            ref="items"
            :headers="headers"
            :items="items"
            :items-per-page= "500"
            :search="search"
            no-data-text="등록된 자료가 없습니다."
            fixed-header
            height="640px"
            hide-default-footer
            @click:row="infoDetail"
            sort-by="user_name"
            group-by="경영체"
            show-group-by
            item-key="account"
            single-select
            :item-class="itemRowBackground"
          ></v-data-table>
      </v-card>      
        </v-layout>
    </v-card>
  </v-container>
</template>

<script>
import Apis from '@/api/apis'
import dateUtil from "@/utils/custom/dateProperty.js";
import Common from "@/utils/custom/common.js";

var save_account = "";

export default {
  name: 'RegisterUser',

  data: () => ({
    items: [],
    search:'',

    headers: [
      { text: '성명', value: 'user_name', width: "120px", groupable: false, class: 'green darken-4 white--text' },
      { text: 'ID', align: 'left', sortable: true, value: 'account', width: "100px", groupable: false, class: 'green darken-4 white--text'},
      { text: '경영체', value: '경영체', width: "100px", class: 'green darken-4 white--text', groupable: true },
      { text: '사용자 유형', sortable: false, value: 'grade', width: "120px", groupable: false, class: 'green darken-4 white--text',},
      { text: '직책', sortable: false, value: 'duty', width: "110px" , groupable: false, class: 'green darken-4 white--text'},
      { text: '연락처', sortable: true, value: 'phone1', width: "150px" , groupable: false, class: 'green darken-4 white--text'},
      { text: '알림', sortable: false, value: 'call_yn', width: "50px", groupable: false, class: 'green darken-4 white--text' },
      { text: '이메일', sortable: false, value: 'email1', width: "100px" , groupable: false, class: 'green darken-4 white--text'},
      { text: '등록일시', sortable: false, value: 'regdate', width: "120px", groupable: false, class: 'green darken-4 white--text' },
      { text: '종료일', sortable: false, value: 'deldate', width: "120px", groupable: false, class: 'green darken-4 white--text' },
      { text: '비고', sortable: false, value: 'history', width: "300px", groupable: false, class: 'green darken-4 white--text' },
    ],

    editedIndex: -1,
    editedItem: {
      company: {},
      farm: {},
      controlHistory: "",
    },
    defaultItem: {
      company: {},
      farm: {},
      controlHistory: "",
    },
    deleteControlID: "",
    deleteFarmName: "",

    dialogNewControl: false,
    dialogDelete: false,
    
    controlItems: [],
    controlHeaders: [
      { text: '관할경영체', align: 'left', sortable: true, value: 'company', width: "300px" },
      { text: '관할 농장/사업장', value: 'farm', width: "300px"},
      { text: 'Actions', align: 'right', value: 'actions', sortable: false, width: "30px" },
    ],
    company: {},
    companyItems: [],
    
    preUser_ID: "",
    user_ID: "",
    user_name: "",
    phone: "",
    call_YN: "",
    user_type: "",
    user_duty: "",
    email_id: "",
    email_host: "",
    joindate: "",
    deldate: "",
    history: "",
    selOption: "live",
    
    // businessDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    modal: false,
    menuJoindate: false,
    menuDeldate: false,
    
    loadingUser: false,
    loadingControl: false,
    loadingSave: false,
    loadingSaveControl: false,

    hintUser: "",
    hintControl: "",

    controlCompany: {},
    controlCompanyItems: [],
    controlFarm: {},
    controlFarmItems: [],
    controlHistory: "",
    
    user_ID_disabled: true,

    msgFlag: true,
  }),

  created() {
    Apis.pageCount({
      page_name: this.$route.name,
      url: this.$route.path,
      division: "pigro",
    },() => {  // 정상처리
      // console.log("pageCount 호출",res);
    }).catch( (err) => {  // 개별 API 오류 처리 예제
      console.log("pageCount 호출 오류",err);
    });
    // 사용자 소속 경영체 initial
    this.comboCompanies();

    // 사용자 관할 경영체 initial
    this.comboControlCompanies();
    this.comboControlFarms();

    this.refreshList();
  },

  methods: {
    clearData() {
      this.company = {};
      this.preUser_ID = "";
      this.user_ID = "";
      this.user_name = "";
      this.phone = "";
      this.call_YN = "";
      this.user_type = "";
      this.controlItems = [];
      this.user_duty = "";
      this.email_id = "";
      this.email_host = "";
      this.joindate = "";
      this.deldate = "";
      this.history = "";
      this.hintUser = "";

      this.user_ID_disabled = true;
    }, 
    checkData() {
      // console.log("combo data", this.bottom  && this.bottom.code || "empty");
      if (!this.user_ID) {
        this.$refs.user_ID.focus();
        return "사용 ID 를 입력하세요";
      }
      // console.log("소속", this.company.code);
      
      if (!this.company.code) {
        this.$refs.company.focus();
        return "소속 경영체를 선택입력하세요";
      }
      if (!this.user_name.trim()) {
        this.$refs.user_name.focus();
        return "성명을 입력하세요";
      }
      if (!this.phone.trim()) {
        this.$refs.phone.focus();
        return "연락처를 입력하세요";
      }
      if (!this.call_YN) {
        this.$refs.call_y.$el.children[1].focus()
        return "알림 동의 여부를 선택하세요";
      }
      if (!this.user_type) {
        this.$refs.user_type.$el.children[1].focus()
        return "사용자 유형 선택 설정하세요";
      }
      if (!this.joindate) {
        this.$refs.joindate.focus();
        return "사용개시일을 선택입력하세요";
      }
      return "";
    },
    save() {
      this.$store.commit("resMessage","");
      let msgCheckdata = this.checkData(); 
      if (msgCheckdata){
        this.$store.commit("resMessage",msgCheckdata);
        // alert(msgCheckdata);
        return;
      }
      this.loadingSave = true;
      if (this.preUser_ID) {
        // console.log("업데이트");
        Apis.updateUserFree({
          account: this.preUser_ID,
          belongcompany_cd:	this.company.code,
          user_name : this.user_name.trim(),
          grade:	this.user_type,
          duty:	this.user_duty,
          email1:	this.email_id.trim() + "@" + this.email_host,
          email2:	"",
          phone1:	this.phone.replace(/[^0-9]/g, ''),
          phone2:	"",
          call_yn: this.call_YN,
          joindate:	this.joindate,
          deldate:	this.deldate,
          history: this.history.trim(),
          etc1: "",
          etc2:	"",
          etc3: "",
          },(res) => {  
            if (res.result) {
              this.$store.commit("resMessage",res.message);
              this.msgFlag = false;
              this.loadingSave = false;
              // this.clearData();
              this.refreshList();
            } else {
              this.loadingSave = false;
              this.$refs.user_ID.focus();
              alert(res.message);
            }
          }).catch( (err) => {  // API 오류 처리
              this.loadingSave = false;
              console.log("API 호출 오류",err)
              alert(err);
          }
        )
      } else {
        // console.log("인서트",this.company.code);
        Apis.insertUser({
          account:	this.user_ID.trim(),
          belongcompany_cd:	this.company.code,
          user_name : this.user_name.trim(),
          password:	"0000",
          grade:	this.user_type,
          duty:	this.user_duty,
          email1:	this.email_id.trim() + "@" + this.email_host,
          email2:	"",
          phone1:	this.phone.replace(/[^0-9]/g, ''),
          phone2:	"",
          call_yn: this.call_YN,
          joindate:	this.joindate,
          deldate:	this.deldate,
          history: this.history.trim(),
          etc1: "",
          etc2:	"",
          etc3: "",
          },(res) => {  
            if (res.result) {
              this.$store.commit("resMessage",res.message);
              this.msgFlag = false;
              this.loadingSave = false;
              save_account = res.data;
              this.clearData();
              this.refreshList();
            } else {
              this.loadingSave = false;
              alert(res.message);
            }
          }).catch( (err) => {  // API 오류 처리
              this.loadingSave = false;
              console.log("API 호출 오류",err)
              alert(err);
          }
        )
      }
    },
    infoDetail(value,row) {             // todo 개인 조회시 관할 농장/사업장리스트, 입사일 항목 추가
      row && row.select(true);
      save_account = "";
      this.$store.commit("resMessage","");
      this.loadingUser = true;
      this.clearData();
      Apis.infoUser({
        account: value.account, 
        },(res) => {  
          if (res.result) {
            this.preUser_ID = res.data.account,
            this.user_ID = res.data.account,
            this.user_ID_disable = true;

            this.company = {code: res.data.belongcompany_cd.substr(0,11), name: res.data.belongcompany_cd.substr(11)};
            this.user_name = res.data.user_name; 
            this.user_type = res.data.grade.substr(0,4); 
            this.controlItems = res.data.control_farm;
            this.user_duty = res.data.duty;	
            let email = res.data.email1.split("@",2);
            this.email_id = email[0];
            this.email_host = email[1];
            this.phone = Common.getMask(res.data.phone1);
            this.call_YN = res.data.call_yn; 
            this.joindate = res.data.joindate;	
            this.deldate = res.data.deldate;	
            this.history = res.data.history; 
            this.hintUser = res.data.regdate + "에 등록, " + res.data.chguser + "님이 " + res.data.chgdate + "에 최종 수정";
            this.loadingUser = false;
            this.$store.commit("resMessage",res.message);
          } else {
            alert(res.message);
            this.loadingUser = false;
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("API 호출 오류",err)
            this.loadingUser = false;
            // alert(err);
        }
      ); 
    },
    newRegister() {
      this.$store.commit("resMessage","");
      this.clearData();
      this.user_ID_disabled = false;
      this.control_table_show = false;
      this.$store.commit("resMessage","새로운 사용자 등록 준비가 되었습니다.");
      this.$refs.user_ID.focus();
    },
    refreshList() {
      if (this.msgFlag) this.$store.commit("resMessage","");
      this.loadingUser = true;
      // console.log("리스트");
      Apis.listUsers({
        sel_option: this.selOption,
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd")
      } ,(res) => {  // 정상처리
        // console.log("res",res);
        res.data.forEach(element => {
          element.phone1 = Common.getMask(element.phone1);
        });
        this.items = res.data;

        // console.log(res.data);
        if (this.msgFlag) this.$store.commit("resMessage",res.message);
        this.msgFlag = true;
        this.loadingUser = false;
      }).catch( (err) => {  // 개별 API 오류 처리 예제
        console.log("listUser 호출 오류",err);
        this.$router.push("/");
        this.loadingUser = false;
      }) 
    }, 
    comboCompanies() {
      Apis.comboCompanies({
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.companyItems = [];
            for (let i in res.data) {
            this.companyItems.push({
              code: res.data[i].company_cd,
              name: res.data[i].company_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboCompanies API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    comboControlCompanies() {
      Apis.comboCompanies({
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.controlCompanyItems = [];
            for (let i in res.data) {
            this.controlCompanyItems.push({
              code: res.data[i].company_cd,
              name: res.data[i].company_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboControlCompanies API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    comboControlFarms() {
      Apis.comboFarms({
        company_cd: this.controlCompany && this.controlCompany.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.controlFarmItems = [];
            for (let i in res.data) {
            this.controlFarmItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              code: res.data[i].farm_cd,
              name: res.data[i].farm_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboControlFarms API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    changeControlFarm() {      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      if (this.controlFarm && this.controlFarm.company_cd) {
        this.controlCompany = {code: this.controlFarm.company_cd, name: this.controlFarm.company_name};
      }
    },
    changeCompany() {   // 하위 콤보 지우기;
      this.farm = {};
    },
    changeControlCompany() {   // 하위 콤보 지우기;
      this.controlFarm = {};
    },
    deleteItem (item) {
      this.editedIndex = this.controlItems.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.deleteControlID = this.editedItem.id
      this.deleteFarmName = this.editedItem.company + " : " + this.editedItem.farm
      this.dialogDelete = true
    },
    deleteItemConfirm () {
      this.controlItems.splice(this.editedIndex, 1)
      this.controlDelete()
    },
    closeNewControl () {
      this.dialogNewControl = false
    },
    closeDelete () {
      this.dialogDelete = false
    },
    controlDelete () {
      Apis.deleteUsersControl({
          id:	this.deleteControlID,
          },(res) => {  
            if (res.result) {
              this.$store.commit("resMessage",res.message);
              this.loadingControlSave = false;
              this.controlCompany= {};
              this.controlFarm= {};
              this.controlHistory= "";
              // this.infoDetail({account: this.user_ID});
              this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
              });
              this.dialogDelete = false;
            } else {
              alert(res.message);
            }
          }).catch( (err) => {  // API 오류 처리
              console.log("deleteUsersControl API 호출 오류",err)
              alert(err);
          }
        )
    },
    saveControl () {
      this.$store.commit("resMessage","");
      let msgCheckdata = this.checkData(); 
      if (msgCheckdata){
        this.$store.commit("resMessage",msgCheckdata);
        alert(msgCheckdata + "\n 먼저 입력 저장후 처리하세요");
        this.dialogNewControl = false
        return;
      }


      this.$store.commit("resMessage","");
      if (!this.controlCompany.code) {
        this.$refs.controlCompany.focus();
        alert("추가 관할경영체를 선택입력하세요");
        return
      }
      if (this.$getters.userInfo.grade !== 'UT01') {
        if (!this.controlFarm.code) {
          this.$refs.controlFarm.focus();
          alert("추가 관할농장/사업장을 선택입력하세요");
          return
        }
      }
      this.loadingControlSave = true;
      Apis.insertUsersControl({
          account:	this.user_ID,
          company_cd: this.controlCompany.code,
          farm_cd: this.controlFarm.code || "",
          history: this.controlHistory,
          },(res) => {  
            if (res.result) {
              this.$store.commit("resMessage",res.message);
              this.loadingControlSave = false;
              this.controlCompany= {};
              this.controlFarm= {};
              this.controlHistory= "";
              this.infoDetail({account: this.user_ID});
            } else {
              this.loadingControlSave = false;
              alert(res.message);
            }
          }).catch( (err) => {  // API 오류 처리
              this.loadingControlSave = false;
              console.log("API 호출 오류",err)
              alert(err);
          }
        )
      },
    getPhoneMask(val) {
      this.phone = Common.getMask(val)
    },
    itemRowBackground(item) {
      return item.account == save_account  ? 'selected' : '' ;
    },

  }  // -----------methods 

}
// 01-06
</script>
<style lang="scss" scoped>
@media screen and (max-width: 820px){
  .flex_mobile{
    flex-wrap: wrap;
  }
  .dataTable{
    max-width:100% !important;
  }
}
.dataTable{
  max-width:60%;
}
.small-text {
  font-size: 17px;
}
.margin-tool {
  margin-top: -10px;
  margin-bottom: -5px;
}
.layout-class {
  font-weight: bold;
  font-size: 14px;
  color:white;
  background-color: #436251 ;
}
tr.v-data-table__selected {
  background: #edfcf0 !important;
}
.selected {
  background-color: #edfcf0 !important;
}
.normal-btn{
  background-color: #3a4f3f !important;
  color:white;
  width:100px;
  height:45px !important;
  font-weight:bold;
  font-size:15px;
  letter-spacing: -0.5px;
  border-radius:20px;
  border-color:transparent;
}
.new-btn{
  color:#3a4f3f !important;
  width:100px;
  height:45px !important;
  font-weight:bold;
  font-size:15px;
  letter-spacing: -0.5px;
  border-color:transparent;
  border-radius:20px;
  background-color: rgb(248, 248, 248) !important;
}
.dialog-btn{
  color:white !important;
  font-size:12pt;
  font-weight: bold !important;
  border-radius:20px;
  background-color: #88191a !important;
}

.v-data-table::v-deep{
  thead.v-data-table-header tr {
    th {
      background-color: #436251 !important;
      color: white !important;
      font-size:13px;
      height:40px !important;
    }
  }
} 
</style>
